import { SetStateAction, useState } from "react";

import { useSelector } from "react-redux";

import classes from "./UserRecords.module.css";
import { RootState } from "../features/app/Store";
import { UserAccessType } from "../features/user/UserStore";
import AddingNewUserRecordPopUp from "../features/user_records/AddingNewUserRecordPopUp";
import UserRecordsTable from "../features/user_records/user_records_table/UserRecordsTable";
import SolarGikButton from "../SolarGikLib/Button";
import Card from "../SolarGikLib/cards/Card";
import { narrowBlueButton } from "../SolarGikLib/styles/ButtonsStyle";
const UserRecords = () => {
  const userType = useSelector((state: RootState) => state.user.userType);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);
  const onOpen = (event: {
    currentTarget: SetStateAction<HTMLElement | null>;
  }) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  return (
    <>
      <AddingNewUserRecordPopUp
        isOpen={open}
        onClose={onClose}
        anchorEl={anchorEl}
      />
        <div className={classes.center}>
          <div className={classes["add-report"]}>
            <SolarGikButton
              isDisabled={userType < UserAccessType.Operator}
              style={narrowBlueButton}
              onClickFunc={onOpen}
              text="Add new record"
            />
          </div>
          <UserRecordsTable />
        </div> 
    </>
  );
};

export default UserRecords;

import { FC } from "react";
import { humanizeEnumValue } from "../../common/EnumUtils";
import classes from "./CurrentState.module.css";
import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import getIcons from "../../SolarGikLib/icons/Icons";
import { IconCategory, FieldIcons } from "../../SolarGikLib/icons/IconsModels";
import { TrackersStateEnum } from "./TrackersModels";

interface CurrentStateProps {
  currentState?: number;
  targetState?: number;
}
const CurrentState: FC<CurrentStateProps> = ({ currentState, targetState }) => {
  const ArrowIcon = getIcons(FieldIcons.RightArrow, IconCategory.Fields);
  let state = humanizeEnumValue(TrackersStateEnum[currentState!]);
  let isInProgress = false;

  if (currentState == TrackersStateEnum.InProgress) {
    state = humanizeEnumValue(TrackersStateEnum[targetState!]);
    isInProgress = true;
  }
  return (
    <div className={classes.row}>
      <div className={classes["right-arrow"]}>
        {isInProgress && <ArrowIcon />}
      </div>
      <div className={classes["current-state"]}>
        {state ?? NO_VALUE_PLACEHOLDER}
      </div>
    </div>
  );
};
export default CurrentState;

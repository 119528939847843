import { useState } from "react";
import { useSelector } from "react-redux";
import classes from "./TrackersCommand.module.css";
import TrackersCommandResultPopUp from "./TrackersCommandResultPopUp";
import TrackersControlCommandForm from "./TrackersControlCommandForm";
import SolarGikButton from "../../../SolarGikLib/Button";
import { narrowOutlinedWhiteButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import { RootState } from "../../app/Store";
import { selectCurrentResults } from "../../app/store/TrackersCommandsStore";
import commandsSchema from "./TrackerCommandsSchema";
import { TrackersCommandType } from "../TrackersModels";
import { selectFieldState } from "../../app/store/MultisiteTagsStore";
import { FieldState } from "../../data_point/models/TagsModels";

const lastCommandButtonStyle = {
  ...narrowOutlinedWhiteButton,
  margin: "0px",
  width: "100%",
  padding: "6px 0px",
  "@media screen and (max-width: 1600px)": {
    display: "inline-flex",
    margin: "0px",
    fontSize: "10px",
  },
};
interface TrackersCommandProps {
  selectedTrackerIds: number[];
}
const TrackersCommand = ({ selectedTrackerIds }: TrackersCommandProps) => {
  const trackersCommands = useSelector((state: RootState) =>
    selectCurrentResults(state)
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const showResultsClick = () => {
    setDialogOpen(true);
  };
  const closeDialogClick = () => {
    setDialogOpen(false);
  };
  const isCommandResultNotEmpty = Object.keys(trackersCommands).length > 0;
  const userType = useSelector((state: RootState) => state.user.userType);
  const anyCommandAllowed = Object.keys(commandsSchema).some(
    (key) =>
      userType >= commandsSchema[key as TrackersCommandType].minimumUserRole
  );
  const fieldState = useSelector((state: RootState) =>
    selectFieldState(state, state.site.siteId)
  );
  const isFieldDisconnected = fieldState === FieldState.Disconnected;
  return (
    <div className={classes["commands-form"]}>
      <div>
        <TrackersControlCommandForm
          selectedTrackerIds={selectedTrackerIds}
          afterSendCommand={showResultsClick}
          disabled={
            selectedTrackerIds.length === 0 ||
            !anyCommandAllowed ||
            isFieldDisconnected
          }
        />
      </div>
      <div className={classes["last-command-button"]}>
        <SolarGikButton
          style={lastCommandButtonStyle}
          onClickFunc={showResultsClick}
          isDisabled={!isCommandResultNotEmpty}
          text="Open last command"
        />
      </div>

      <TrackersCommandResultPopUp
        open={dialogOpen}
        onClose={closeDialogClick}
      />
    </div>
  );
};

export default TrackersCommand;

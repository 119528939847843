import { useEffect } from "react";
import { useSelector } from "react-redux";
import classes from "./PixelsView.module.css";
import { RootState } from "../../app/Store";
import {
  initializePixelsCanvas,
  solarSightProvider,
} from "./pixelsClientWrapper";

interface IPixelViewProps {
  siteId: string;
}

export const PixelsView = ({ siteId }: IPixelViewProps) => {
  solarSightProvider.trackersStatus = useSelector(
    (state: RootState) => state.multiSitesTrackers.trackersStatus[siteId] || []
  );

  useEffect(() => {
    const abortController = new AbortController();
    initializePixelsCanvas(siteId, abortController.signal);
    return () => abortController.abort();
  }, []);

  return <canvas className={classes["unity-canvas"]} id="solar-sight-canvas" />;
};

export const enum IconCategory {
  Error = "error",
  Status = "status",
  General = "general",
  FileCommands = "fileCommands",
  Loaders = "loaders",
  Fields = "fields",
  Soma = "soma",
  Auxiliaries = "auxiliaries",
  Reason = "reason",
  ScheduleCommand = "scheduleCommand",
}

export const enum ErrorIcons {
  DataPointError = "DataPointError",
  HighSeverityError = "HighSeverityError",
  MildSeverityError = "MildSeverityError",
  LowSeverityError = "LowSeverityError",
  UndefinedSeverityError = "UndefinedSeverityError",
  MultisiteHighSeverityError = "MultisiteHighSeverityError",
  MultisiteMildSeverityError = "MultisiteMildSeverityError",
  MultisiteLowSeverityError = "MultisiteLowSeverityError",

  MultisiteUndefinedError = "MultisiteUndefinedSeverityError",
  EmergencyError = "EmergencyError",
  EmergencyErrorHover = "EmergencyErrorHover",
}

export const enum AuxiliariesStatusIcons {
  Battery = "Battery",
  BatteryInvalid = "BatteryInvalid",
  Inverter = "Inverter",
  InverterInvalid = "InverterInvalid",
  Grid = "Grid",
  GridInvalid = "GridInvalid",
  Tracker = "Tracker",
  TrackerInvalid = "TrackerInvalid",
  StatusMark = "StatusMark",
  Ok = "Ok",
  Warning = "Warning",
  Error = "Error",
  Undefined = "Undefined",
}

export const enum GeneralIcons {
  UserRecords = "UserRecords",
  UserRecordsDisabled = "UserRecordsDisabled",
  SolarGikLogo = "SolarGikLogo",
  UserIcon = "UserIcon",
  Separator = "Separator",
  TrackerBattery = "TrackerBattery",
}

export const enum FileCommandsIcons {
  Download = "Download",
  WhiteDownload = "WhiteDownload",
  DownloadDisabled = "DownloadDisabled",
  Upload = "Upload",
  WhiteUpload = "WhiteUpload",
  UploadDisabled = "UploadDisabled",
  Delete = "Delete",

  DeleteDisabled = "DeleteDisabled",

  Send = "Send",
  WhiteSend = "WhiteSend",
  Close = "Close",
}

export const enum LoadersIcons {
  ChartLoader = "ChartLoader",
}

export const enum FieldIcons {
  ArrowDown = "ArrowDown",
  ArrowDownDisabled = "ArrowDownDisabled",
  FocusedArrowDown = "FocusedArrowDown",
  RightArrow = "RightArrow",
  Calender = "Calender",
  ListItemSelected = "ListItemSelected",
}

export const enum SomaIcons {
  TrendLine = "TrendLine",
  ACPower = "ACPower",
  ACPowerInvalid = "ACPowerInvalid",
  PlantGrowth = "PlantGrowth",
  PlantGrowthInvalid = "PlantGrowthInvalid",
  Photosyn = "Photosyn",
  PhotosynInvalid = "PhotosynInvalid",
  RainGouge = "RainGouge",
  RainGougeInvalid = "RainGougeInvalid",
  BarometricPressure = "BarometricPressure",
  BarometricPressureInvalid = "BarometricPressureInvalid",
  DCPower = "DCPower",
  DCPowerInvalid = "DCPowerInvalid",
  Wind = "Wind",
  WindInvalid = "WindInvalid",
  Temperature = "Temperature",
  TemperatureInvalid = "TemperatureInvalid",
  Sun = "Sun",
  SunInvalid = "SunInvalid",
  ClosedEye = "ClosedEye",
  OpenedEye = "OpenedEye",
  Plus = "Plus",
  EditLine = "EditLine",
  Shading025 = "Shading025",
  Shading2550 = "Shading2550",
  Shading5075 = "Shading5075",
  Shading75100 = "Shading75100",
  Connector = "Connector",
  Clock = "Clock",
  SprinklerOn = "SprinklerOn",
  SprinklerOff = "SprinklerOff"
}

export const enum AuxiliariesIcons {
  Inverter = "Inverter",
  Tracker = "Tracker",
}

export const enum ReasonIcons {
  Wind = "Wind",
  Snow = "Snow",
  Sprinklers = "Sprinklers",
  Tracker = "Tracker",
  Maintenance = "Maintenance",
  Software = "Software",
}

export const enum ScheduleCommandIcons {
  ExistsScheduledCommand = "ExistsScheduledCommand",
  Recurrence = "Recurrence",
  ActiveScheduledCommand = "ActiveScheduledCommand",
}

import { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { useSelector } from "react-redux";

import { MaintenanceReasonRow } from "./MaintenanceReasonRow";
import classes from "./TrackersControlCommandFormUi.module.css";
import SolarGikAlert from "../../../SolarGikLib/alerts/Alert";
import { AlertMessage } from "../../../SolarGikLib/alerts/AlertModels";
import SolarGikButton from "../../../SolarGikLib/Button";
import SolarGikAutocomplete from "../../../SolarGikLib/fields/Autocomplete";
import SolarGikInput from "../../../SolarGikLib/fields/Input";
import { inputStyle } from "../../../SolarGikLib/fields/InputStyle";
import getIcons from "../../../SolarGikLib/icons/Icons";
import {
  FieldIcons,
  FileCommandsIcons,
  IconCategory,
} from "../../../SolarGikLib/icons/IconsModels";
import { narrowBlueButton } from "../../../SolarGikLib/styles/ButtonsStyle";
import { RootState } from "../../app/Store";
import { getMaintenanceOptionListConfig } from "../MaintenanceOptionListConfig";
import {
  TrackerElevation,
  TrackersCommandType,
  ElevationDirections,
  IMaintenanceListitem,
} from "../TrackersModels";
import commandsSchema, { CommandsSchema } from "./TrackerCommandsSchema";
import { selectSiteId } from "../../sites/SiteStore";
import ScheduleCommand from "../ScheduleCommand/ScheduleCommand";
import { FeatureFlags } from "../../featureFlags/FeatureFlagsModels";

const sendButtonStyle = {
  ...narrowBlueButton,
  width: "100%",
  "@media screen and (max-width: 1600px)": {
    display: "inline-flex",
    margin: "0px",
    fontSize: "10px",
  },
};

interface TrackersControlCommandFormUiProps {
  command: TrackersCommandType | undefined;
  setCommand: (command: TrackersCommandType) => void;
  elevation: TrackerElevation;
  setElevation: (elevation: TrackerElevation) => void;
  reason: string | null;
  setReason: (reason: string | null) => void;
  alertMessage?: AlertMessage;
  setAlertMessage: (message?: AlertMessage) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  disabled: boolean;
  selectedTrackerIds: number[];
  elevationZenith: number;
}

const elevationDirectionOptions = Object.values(ElevationDirections);
const allowedScheduleCommands = [TrackersCommandType.SendTrackersToMaintenance];

const TrackersControlCommandFormUi = (
  props: TrackersControlCommandFormUiProps
) => {
  const {
    command,
    setCommand,
    elevation,
    setElevation,
    reason,
    setReason,
    alertMessage,
    setAlertMessage,
    handleSubmit,
    disabled,
    selectedTrackerIds,
    elevationZenith,
  } = props;

  const userType = useSelector((state: RootState) => state.user.userType);
  const siteId = useSelector(selectSiteId);
  const featureFlags = useSelector(
    (state: RootState) => state.featureFlags.sitesFeatureFlags[siteId]
  );

  const [isScheduleDialogOpen, setIsScheduleDialogOpen] =
    useState<boolean>(false);

  const visibleCommands = Object.entries(commandsSchema)
    .filter(([, command]) =>
      (!command.featureFlag || featureFlags.includes(command.featureFlag)) &&
      userType >= command.minimumUserRole)
    .reduce((accumulator, [key, command]) => {
      accumulator[key as TrackersCommandType] = command;
      return accumulator;
    }, {} as CommandsSchema);
  const commandTypeOptions = Object.keys(visibleCommands);

  const setElevationFunc = (event: { target: { value: number } }) => {
    setElevation({
      value: Number(event.target.value),
      direction: elevation.direction ?? ElevationDirections.West,
    });
  };

  const onClickSchedule = () => {
    setIsScheduleDialogOpen(true);
  };

  const handleClose = () => {
    setIsScheduleDialogOpen(false);
  };
  const setElevationDirectionFunc = (
    _event: React.SyntheticEvent,
    value: ElevationDirections
  ) => {
    setElevation({
      value: elevation.value ?? 0,
      direction: value,
    });
  };

  const SendIcon = getIcons(
    FileCommandsIcons.WhiteSend,
    IconCategory.FileCommands
  );
  const ArrowIcon = getIcons(FieldIcons.ArrowDown, IconCategory.Fields);
  const DisabledArrowIcon = getIcons(
    FieldIcons.ArrowDownDisabled,
    IconCategory.Fields
  );
  const reasonListConfig = getMaintenanceOptionListConfig(userType);
  const directionInputDisabled =
    disabled || (command && !visibleCommands[command].shouldSpecifyDirection);
  const elevationInputDisabled =
    disabled || (command && !visibleCommands[command].shouldSpecifyElevation);
  const reasonRequired = !!(
    command && visibleCommands[command]?.shouldSpecifyReason
  );
  const reasonInputDisabled = disabled || !reasonRequired;
  return (
    <form onSubmit={handleSubmit}>
      <SolarGikAlert message={alertMessage} setMessage={setAlertMessage} />
      <div className={classes.form}>
        <div className={classes.item}>
          <SolarGikAutocomplete
            options={commandTypeOptions}
            renderInputFunc={(params) => (
              <TextField
                {...params}
                label="Command"
                sx={inputStyle}
                InputLabelProps={{ className: classes["input-text-size"] }}
              />
            )}
            onChange={(
              _event: React.SyntheticEvent,
              value: TrackersCommandType
            ) => {
              setCommand(value);
            }}
            value={command}
            key={"select command"}
            id={"select command"}
            textSize={classes["input-text-size"]}
            popUpIcon={disabled ? <DisabledArrowIcon /> : <ArrowIcon />}
            disabled={disabled}
            size={"small"}
          />
        </div>
        <div className={`${classes.item} ${classes["elevation-row"]}`}>
          <div className={classes["elevation-direction"]}>
            <SolarGikAutocomplete
              options={elevationDirectionOptions}
              renderInputFunc={(params) => (
                <TextField
                  {...params}
                  sx={inputStyle}
                  label="Direction"
                  InputLabelProps={{ className: classes["input-text-size"] }}
                />
              )}
              value={elevation.direction}
              onChange={setElevationDirectionFunc}
              key={"Elevation direction"}
              id={"Elevation direction"}
              textSize={classes["input-text-size"]}
              popUpIcon={
                directionInputDisabled ? <DisabledArrowIcon /> : <ArrowIcon />
              }
              disabled={directionInputDisabled}
              size={"small"}
            />
          </div>
          <div className={classes["elevation-value"]}>
            <SolarGikInput
              disabled={elevationInputDisabled}
              type="number"
              label="Elevation"
              forceLabel
              placeholder="Elevation"
              value={elevation.value}
              onChange={setElevationFunc}
              inputPropsExtras={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span>
                      {elevation.direction === ElevationDirections.West
                        ? "+"
                        : "-"}
                    </span>
                  </InputAdornment>
                ),
              }}
              textSize={classes["input-text-size"]}
              size={"small"}
            />
          </div>
        </div>
        <div className={classes.item}>
          <SolarGikAutocomplete
            options={reasonListConfig}
            renderInputFunc={(params) => (
              <TextField
                {...params}
                label="Reason"
                sx={inputStyle}
                InputLabelProps={{ className: classes["input-text-size"] }}
              />
            )}
            getOptionLabel={(option) => (option?.title ? option.title : "")}
            renderOption={(props, option) => (
              <div>
                <li {...props}>
                  {option && (
                    <MaintenanceReasonRow
                      Icon={option.icon}
                      title={option?.title ?? ""}
                    />
                  )}
                </li>
              </div>
            )}
            onChange={(
              _event: React.SyntheticEvent,
              value: IMaintenanceListitem
            ) => {
              setReason(value.title);
            }}
            value={null}
            key={"select reason"}
            id={"select reason"}
            textSize={classes["input-text-size"]}
            popUpIcon={
              reasonInputDisabled ? <DisabledArrowIcon /> : <ArrowIcon />
            }
            disabled={reasonInputDisabled}
            size={"small"}
          />
        </div>
        {featureFlags.includes(FeatureFlags.ScheduledMaintenance) &&
          allowedScheduleCommands.includes(command as TrackersCommandType) && (
            <div className={classes.item}>
              <SolarGikButton
                onClickFunc={onClickSchedule}
                style={sendButtonStyle}
                isDisabled={disabled || (reasonRequired && !reason)}
                text="Schedule"
                type="button"
              />
            </div>
          )}
        <div className={classes.item}>
          <SolarGikButton
            icon={<SendIcon />}
            style={sendButtonStyle}
            isDisabled={disabled || (reasonRequired && !reason)}
            text="Send"
            type="submit"
          />
        </div>
      </div>
      <ScheduleCommand
        onClose={handleClose}
        isOpen={isScheduleDialogOpen}
        command={command as TrackersCommandType}
        reason={reason ?? ""}
        trackerIds={selectedTrackerIds}
        elevationZenith={elevationZenith}
      />
    </form>
  );
};

export default TrackersControlCommandFormUi;
